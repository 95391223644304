.upcomingCards {
    display: flex;
}
.upcomingLogo {
    margin-right: 10px !important;
}
.upcomingEventWrapper{
    margin: auto 0 !important;
}
.upcomingDateandTime {
    font-size: 12px !important;
    font-weight: 700 !important;
}
.upcomingEvents {
    font-size: 12px !important;
}