.filterBox {
    display: flex;
    width: auto;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
}
.filterButton {
    padding: 6px 16px;
    margin: auto;
}
.filterIcon {
    padding-right: 10px;
}

.filterWrapper {
    box-shadow: 5px 5px 10px #cccccc80;
    position: absolute;
    left: 120px;
    border-radius: 8px;
    z-index: 3;
    background-color: #fff;
    padding: 20px;
}

.filterConditionWrapper {
    display: flex;
    margin-bottom: 10px;
}

.filterSelector {
    margin-right: 10px;
}

.closeIconWrapper {
    display: block;
    text-align: end;
}

.filterDeleteContainer {
    margin-top: 26px;
}

.filterConditionContainer {
    margin-top: 18px;
    margin-right: 10px;
}

.filtersContainer {
    max-height: 300px;
    overflow-y: auto;
}

.filterCount {
    width: 16px;
    height: 16px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    right: -6px;
    top: -4px;
    padding: 2px;
    font-size: 11px;
}