.ReportCommentWrapper {
    width: '100%';
    padding: 16px;
    /* height: calc(100% - 28px) !important; */
}
.commentBoxHeader {
    display: flex;
    justify-content: space-between;
}
.commentBoxContainer {
    display: flex;
}
.chatWrapper {
    width: '100%';
    /* padding: 16px; */
    /* overflow-y: auto; */
}
.tabPanelComments {
    padding: 0px !important;
    height: calc(100% - 55.25px);
}