.currentClubContainerWrapper {
    width: max-content;
    position: absolute;
    top: 0;
    right: 0;
    /* border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; */
    /* border: 2px solid #7D8793; */
}

.currentClubDetail {
    z-index: 2;
    position: relative;
    display: flex;
    border-right: 0;
    padding: 5px 10px;
    display: flex;
    min-width: 240px;
    max-width: 400px;
    justify-content: space-between;
}

.logoContainer {
    margin: auto 0px auto 10px;
    width: 28px;
    height: 28px;
}
.changeIconContainer {
    margin-top: auto;
    margin-bottom: auto;
    border: 2px solid #fff;
    border-radius: 50%;
}
.changeIcon {
    color: #fff;
    padding: 3px;
    width: 14px;
    height: 12px;
}
.clubIndicatorLogo {
    width: 24px;
}
.clubIndicatorTitle {
    margin-top: auto !important;
    margin-bottom: auto !important;
    font-weight: 700 !important;
    color: #fff;
    font-family: Noto Sans !important;
    font-size: 14px !important;
    padding-left: 30px;
}
.clubNameContainer {
    display: flex;
}

.slideableSearhBarWrapper {
    position: fixed;
    z-index: 1;
    top: 0;
}