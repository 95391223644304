.draggable {
    position: fixed;
    top: 10px;
    left: 10px;
    max-width: 200px;
    z-index: 9999;
  }
  
  .draggable-panel {
    padding: 10px;
    background-color: #F6C008;
    cursor: move;
    border-radius: 8px;
    margin: none;
    display: flex;
    width: fit-content;
  }

  .draggable-panel-typography {
    font-weight: 800 !important;
    font-size: 12px !important;
  }
  .draggable-panel-icon {
    cursor: pointer;
    margin: auto;
    margin-left: 10px;
  }

  .draggable-content {
    padding: 20px;
    width: fit-content;
    background-color: #fff;
    margin-top: 6px;
    box-shadow: 0px 10px 20px 2px #ccc;
    position: relative;
  }
  .xMarkIcon {
    margin-left: auto;
    position: absolute;
    right: 8px;
    top: 8px;
  }