.upcomingIcons {
    margin-right: 10px;
}
.upcomingWrapper {
    border-radius: 8px;
    height: calc(100% - 16px);
    overflow-y: hidden;
    text-align: left;
    padding: 16px;
}
.upComingCardsWrapper {
    height: calc(100% - 48px);
    overflow-y: auto;
    overflow-x: hidden;
}
.fc-scrollgrid {
    background-color: #fff;
}