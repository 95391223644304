.detailedCriteriaHeading {
    display: flex;
    justify-content: space-between;
    background-color: #cccccc80;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 6px !important;
}
.detailedCriteriaTitle {
    margin: auto 0 auto 16px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
}
.detailedCriteriaReports {
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 12px !important;
}

.detailedcriteriaFooter{
    display: flex;
    justify-content: space-between;
    background-color: #cccccc80;
    border-radius: 5px;
    padding: 10px !important;
    margin-top: 10px;
}
.detailedCriteriaButton {
    height: 38px;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.detailedcriteriaContent {
    height: 60vh;
    /* overflow-y: hidden; */
    margin-top: 5px;
}