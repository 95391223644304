.chatDataHeader {
    display: flex;
}
.chatNameDetail {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;
}
.chatTextContainer {
    margin-top: 4px;
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 10px;
    border-top-left-radius: 0px;
    width: fit-content;
    margin-left: 40px;
    margin-right: auto;
}
.chatDataContainer {
    max-width: 800px;
    margin: 10px 0px;
    text-align: justify;
}
.chatDataContainerOwner {
    margin-left: auto;
}
.chatDataHeaderOwner {
    flex-direction: row-reverse;
}
.chatNameDetailOwner {
    text-align: right;
}
.chatTextContainerOwner {
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    margin-right: 40px;
    margin-left: auto;
}