.simpleTabPanel .MuiBox-root {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
}
.projectDetailsHeader {
    display: flex;
    background-color: #cccccc80;
    border-radius: 5px;
    padding: 6px 16px;
    justify-content: space-between;
}
.projectDetailsHeading {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: transparent;
    width: 50%;
}
.projectDetailsTitle {
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
}
.perfActionTableWrapper {
    height: calc(100% - 150px);
}
.PDselectBoxWrapper {
    display: grid;
    grid-template-columns: 260px 260px;
    width: 40%;
}