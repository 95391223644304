.teamDetailsStyles {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 14px;
}
.noPlayersStyles {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 14px;
}
.teamFeeStyles {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 14px;
}