/* card-styles */
.myClubCard {
    display: flex;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px #cccccc80;
    font-size: 12px;
    /* height: 80px; */
    width: 242px;
    /* margin-right: 20px; */
    margin-bottom: 20px;
    cursor: pointer;
    justify-content: space-between;
}
.clubCardClubName {
    font-weight: 600;
}
.myClubCardCircular {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}
.cardDetail {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    width: 100%;
}
.selfAssessmenBox {
    display: flex;
    justify-content: space-between;
}