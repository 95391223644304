.PNADmainWrapper {
    display: flex;
    flex-direction: column;
}
.maxWidth70p {
    max-width: 70%;
}
.PNADselectBoxWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}