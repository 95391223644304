.commentsOverviewWrapper {
    /* max-height: 38.5vh; */
    height: 100% !important;
    padding: 16px !important;
    border-radius: 8px;
    /* overflow-y: auto; */
}
.cobSortContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}
.cobSortBoxWrapper {
    display: flex;
    justify-content: space-between;
}
.cobSwitchContainer {
    width: 120px;
}
.cobSearchBoxStyle {
    font-size: 12px !important;
    margin-right: 0px !important;
}