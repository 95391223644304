.resizable-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .top {
    overflow: auto;
    max-height: 95%;
  }
  
  .separator {
    height: 5px;
    background-color: #ccc;
    cursor: row-resize;
  }
  
  .bottom {
    flex: 1;
    overflow: auto;
    display: flex;
  }
  