.directoryWrapper {
    display: flex;
    justify-content: space-between;
}
.directoryContainer {
    display: flex;
    height: 24px;
    position: relative;
}
.directoryWrapper:hover {
    background-color: #b2b0b070;
}
.currentFolder {
    background-color: #b2b0b037;
}
.directoryIcons {
    margin-right: 14px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
}
.renameWrapper {
    display: flex;
    justify-content: space-between;
    width: '100%';
}
.iconStyles {
    color: #868686a8;
    margin-right: 10px;
}
.textfieldStyle {
    padding: 0px !important;
}

.renameOptionsWrapper {
    display: flex !important;
}

.carrotIconsContainer {
    position: absolute;
    left: -22px;
}
