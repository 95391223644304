.homeContainersWrapper {
    display: flex;
    height: 100vh;
    margin-top: 10px;
}
.myClubsWrapper {
    width: 75%;
    height: 100%;
    margin-right: 10px;
}
.homeRightWrapper {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 100%;
}