.tableExpandIcon {
    position: absolute;
    top: 12px;
    left: 52px;
    z-index: 10;
}

.pointer {
    cursor: pointer;
}

.hoverBG:hover {
    background-color: #d6d7da82;
}