.myClubCardsContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    margin-top: 12px;
}
.myClubsContentWrapper {
    overflow-y: hidden;
    height: 98%;
}
.myClubsDetails {
    border: 2px solid;
    border-radius: 8px;
    padding: 16px;
    height: 100%;
}
.clubViewIconsWrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}
.clubViewIcons {
    margin-right: 10px;
}
.globalClubCardsWrapper {
    max-height: 45%;
    overflow-y: auto;
    overflow-x: hidden;
}