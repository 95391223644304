/* .selectBoxPrimaryWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -6px;
    margin-right: 10px;
}
.selectBoxPrimaryLabel {
    font-size: 12px !important;
    display: inline-flex !important;
} */
.selectBoxPrimarySelect {
    min-width: 160px;
    font-size: 12px !important;
    /* background-color: #E9ECF0; */
    /* border: 2px solid #6a6a6a9b; */
    cursor: pointer;
}
.selectBoxPrimarymenuItem {
    font-size: 12px !important;
}