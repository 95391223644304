.footerContainer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: 10px;
    margin-left: 43px;
}
.footerContainer img {
    width: 150px;
}
.footerContainer span {
    font-size: 8px;
    margin-top: auto;
    margin-right: 60px;
}