.selectBoxWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: -6px; */
    margin-right: 10px;
}
.selectBoxLabel {
    font-size: 12px !important;
    /* display: inline-flex !important; */
    text-align: left;
}
.selectBoxSelect {
    min-width: 80px;
    height: 38px;
    font-size: 12px !important;
    background-color: transparent;
    /* border: 2px dotted #6a6a6a9b; */
}
.selectBoxmenuItem {
    font-size: 12px !important;
}