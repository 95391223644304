.standardsDetailFooter {
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px !important;
}
.footerSelectWrapper {
    display: flex;
    margin: auto 0;
}
.selectLabelSDF {
    font-size: 13px !important;
    font-weight: 700 !important;
    margin-right: 10px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.closeButtonSFD{
    height: 24px;
    color: #2e2e2ec3 !important;
    background-color: #cccccc6e !important;
    text-decoration: 'capitalize';
}

.standardsHeading {
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-bottom: 5px !important;
}

.standardardsDetailWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailsWrapper {
    padding: 0px 20px;
    margin-top: 20px;
}

.standardsDetailsCommentsWrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
}
.SDcomments {
    width: 75%;
}
.SDuserDetailsWrapper {
    display: flex;
    flex-direction: column;
}
.sdCommentsWrapper {
    display: flex;
}

.standardDetailsContainer {
    background-color: #00000005;
    margin: 10px 0px;
    border-radius: 8px;
    height: calc(100% - 60px);
    overflow-y: auto;
}