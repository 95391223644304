.PeopleHeader {
    display: flex;
    position: relative;
    width: 100%;
  }
  
.SwitchButtonToggle {
    position: absolute; /* Positioning relative to the nearest positioned ancestor */
    right: 0;           /* Align to the rightmost corner */
    top: 50%;           /* Optionally center it vertically */
    transform: translateY(-50%); /* Center the form group vertically based on its height */
  }