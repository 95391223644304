.multiSelectBoxLabel {
    font-size: 12px !important;
    /* display: inline-flex !important; */
    text-align: left;
}

.selectBoxSelect {
    min-width: 80px;
    height: 38px;
    font-size: 12px !important;
    background-color: transparent;
    /* border: 2px dotted #6a6a6a9b; */
}
.multiSelectBoxmenuItem {
    font-size: 12px !important;
}
.membersWrapperSelect {
    display: grid;
    grid-template-columns: auto auto;
}