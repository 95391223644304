.fc-toolbar-title {
    font-size: 14px !important; /* Adjust the font size as needed */
  }

  .fc-daygrid-day-bottom {
    margin-top: -10px !important;
  }

  .fc-today-button {
    padding: 4px !important;
    font-size: 12px !important;
  }

  .fc-icon {
    font-size: 12px !important;
  }

  .fc-popover {
    position: relative;
    top: 0px !important;
    left: 0px !important;
    margin-top: 250px;
    width: 100%;
  }