.actionBarWrapper {
    position: absolute;
    top: -42px;
    z-index: 3;
    background-color: #fff;
    box-shadow: #ccc 2px 10px 10px;
    border-radius: 10px;
    display: flex;
    padding: 10px;
}

.mr28 {
    margin-right: 28px !important;
}