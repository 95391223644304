.NAGmainWrapper {
    display: flex;
    flex-direction: column;
}
.maxWidth70p {
    max-width: 70%;
}
.NAGselectBoxWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.NAGinnerWrapper {
    display: flex;
}
.NAGprivacyTimeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
}