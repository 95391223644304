.liceDataSubContainer {
    margin-bottom: 20px;
}

.detailsWrapper {
    padding: 0px 20px;
    margin-top: 0px;
}
.licenceStandardsHeading {
    font-size: 14px !important;
    font-weight: 700 !important;
    /* margin-bottom: 5px !important; */
}