.fa-sharp.fa-regular.fa-circle-plus {
    transition: transform 0.3s ease, filter 0.3s ease; /* Add smooth transition for transform and filter */
  }
  
  .fa-sharp.fa-regular.fa-circle-plus:hover {
    transform: scale(1.2); /* Scale the icon when hovered */
    cursor: pointer; /* Change the cursor to indicate interactivity */
    filter: brightness(1.2); /* Slightly brighten the icon */
  }
  
  .fa-sharp.fa-regular.fa-circle-plus:active {
    transform: scale(0.9); /* Scale down the icon when clicked */
    filter: brightness(1); /* Reset brightness when clicked */
  }