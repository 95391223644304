.adDetailsWrapper {
    display: flex;
    width: 100%;
}
.adDetails1 {
    width: 70%;
    margin-right: 10px;
}
.adDetails2 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.adTaksContainer {
    width: 100%;
}
.adStatusContainer {
    display: flex;
    width: 100%;
}
.adStatusCommentWrapper {
    width: 70%;
    margin-right: 10px;
}
.adStatusDate {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.adTaskToDos {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}