.App {
  text-align: center;
}

.app-container {
  transform: scale(0.8); /* 80% scale */
  transform-origin: top left;
  width: 125%; /* Compensate for 80% scale */
  height: 125%; /* Compensate for 80% scale */
  overflow: hidden; /* Prevent scrollbars */
}
