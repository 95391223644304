.tableExpandIcon {
    position: absolute;
    top: 12px;
    left: 52px;
    z-index: 10;
}

.conflictRow {
    background-color: #fbc3c98c;
    color: #99626A;
}

.infoIcon {
 position: relative;
 left: 10px;
}