.contentOverviewWrapper {
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    padding: 2px 12px;
}
.statusWrapperContainer {
    display: flex;
    justify-content: space-around;
    width: 40%;
    margin-right: 15%;
}