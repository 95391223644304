.sortBoxWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -8px;
    margin-right: 5px;
}
.sortBoxLabel {
    font-size: 11px !important;
    text-align: left;
}
.sortBoxSelect {
    min-width: 80px;
    height: 20px;
    font-size: 12px !important;
    background-color: #cccccc80;
}
.sortBoxmenuItem {
    font-size: 10px !important;
}