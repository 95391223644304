.DatesHeader {
    display: flex;
    position: relative;
    width: 100%;
  }
  
.SwitchButtonToggle {
    position: absolute; /* Positioning relative to the nearest positioned ancestor */
    right: 0;           /* Align to the rightmost corner */
    top: 50%;           /* Optionally center it vertically */
    transform: translateY(-50%); /* Center the form group vertically based on its height */
  }

/* styles.css or a similar file */
.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 40px; /* Set width of the button */
  height: 40px; /* Set height of the button */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Circular button */
  background-color: transparent; /* Make button transparent */
  transition: opacity 0.3s; /* Add smooth transition on opacity */
}

.iconButton:disabled {
  opacity: 0.5; /* Dim the icon when disabled */
  cursor: not-allowed;
}
