.searchBoxMain {
    display: flex;
    margin-left: 0px;
    width: 33%;
}
.mentionsSearchBox {
    width: 60%;
    height: 30px;
}
.searchIcon {
    width: 24px !important;
    height: 24px !important;
}
.searchDropBox{
    text-align: left;
    padding: 0 10px !important;
}
.searchDropBox:hover {
    background-color: #E9ECF0;
}