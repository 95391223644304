.PADmainWrapper {
    display: flex;
    flex-direction: column;
}
.PADContentWrapper {
    display: flex;
    justify-content: space-between;
}
.maxWidth70p {
    max-width: 70%;
}
.PDAselectBoxWrapper {
    display: grid;
    grid-template-columns: 260px 260px;
}
.PADContentTitleDesc {
    display: flex;
    flex-direction: column;
    width: 100%;
}