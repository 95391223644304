.goalDetailWrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.gdHeader {
    display: flex;
    justify-content: space-between;
}

.headingsGD {
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.gdContents {
    padding: 10px 5px;
}

.headingsGDright {
    font-size: 13px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.gdLeftContent {
    width: 76%;
}
.gdRightContent {
    width: 20%;
}
.dividerLine {
    height: 100%;
    width: 2px;
    background-color: #7D8793;
    position: sticky;
    top: 0;
}

.subGoalsHeader {
    /* display: flex;
    justify-content: space-between; */
    margin-bottom: 10px;
}