body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Noto Sans', sans-serif !important;
    /* font-family: 'Poppins', sans-serif; */
    /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  cursor: default;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appWrapper {
  height: 125vh;
  display: flex;
  flex-direction: column;
}
.contentWrapper {
  padding: 10px 40px;
}
.css-19midj6 {
  padding: 6px !important;
}

/* hover style */
.hoverBG:hover {
  background-color: #F5F5F5;
}
.hoverBGlite:hover {
  background-color: #f5f5f596;
}
/* selected row style */
.selectedRow {
  background-color: #F5F5F5;
}

/* .hoverStar:hover,
.hoverStar:hover ~ .hoverStar {
  color: #dff443 !important;
} */

.hoverStar:hover {
  color: #dff443 !important;
}

/* cursor styles */
.cursorPointer {
  cursor: pointer;
}
.cursorNotAllowed {
  cursor: not-allowed;
}

/* collapse container styles */
.collapseWrapper {
  border: 2px solid #F5F5F5;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  position: relative;
  /* max-height: 58vh; */
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.compressedStyle {
  background-color: #F5F5F5;
  border: 2px solid #F5F5F5;
}
/* .collapsableContainerHeads {
  display: flex;
  justify-content: space-between;
} */
.collapseIcons {
  position: absolute;
  top: 3px;
  right: 0;
  padding: 10px;
  z-index: 5;
  margin-top: auto;
  margin-bottom: auto;
}
.MuiCollapse-root {
  height: fill-available !important;
}
.collapseHeading {
  font-size: 15px !important;
  font-weight: 800 !important;
  text-align: left;
}
.collapseContentWrapper {
  padding: 10px;
  text-align: left;
}
.icon {
  color: #868686a8;
}
.selectedIconColor {
  color: #3d3d3de9;
}

.chipStyle {
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-size: 11px !important;
  height: 24px !important;
}

/* flex styles */
.flex {
  display: flex !important;
}
.displayNone {
  display: none !important;
}
.displayinlineBlock {
  display: inline-block !important;
}

/* icon styles */
.largeFileIcon {
  width: 42px;
  height: 42px;
}
.smallFileIcon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.smallLogoForTables {
  width: 20px;
  height: 20px;
}

/* button styles */
.smallButton {
  font-size: 10px !important;
}
.closeCancelButtonStyles{
  /* height: 24px; */
  color: #2e2e2ec3 !important;
  background-color: #cccccc6e !important;
  text-decoration: 'capitalize';
}

/* popper styles */
.popperCenter {
  position: absolute !important;
  top: 15% !important;
  left: 20% !important;
}
.executiveSummaryPopper {
  position: relative;
  top: 22% !important;
  left: 6% !important;
}

.popperStyle {
  z-index: 999;
  width: 100% !important;
  height: 100% !important;
  backdrop-filter: blur(5px);
}


/* Scroll Bar Styles */
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5555556a; 
}

/* posiiton styles */
.positionFixed {
  position: fixed;
}

/* common spacings */
.contentSpacing {
  margin-left: 60px;
  margin-right: 20px;
  margin-bottom: auto;
  margin-top: 5px;
}
.mtbAuto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mlrAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mb2 {
  margin-bottom: 2px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.p5 {
  padding: 5px !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
/* common text sizes */
.fs12 {
  font-size: 12px !important;
}
.fs13 {
  font-size: 13px !important;
}
.fs14 {
  font-size: 14px !important;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
  font-size: 16px !important;
}
.fs17 {
  font-size: 17px !important;
}
.fs18 {
  font-size: 18px !important;
}
.fs19 {
  font-size: 19px !important;
}
.fs20 {
  font-size: 20px !important;
}
.fs21 {
  font-size: 21px !important;
}
.fs22 {
  font-size: 22px !important;
}
.fs23 {
  font-size: 23px !important;
}
.fs24 {
  font-size: 24px !important;
}

.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.fw800 {
  font-weight: 800 !important;
}
.fw900 {
  font-weight: 900 !important;
}

/* common text decorations */
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

/* common border styles */
.bb0 {
  border-bottom: 0 !important;
}

/* accordion styles */
.accordion32 {
  min-height: 32px !important;
}
.mtNone {
  margin-top: none !important;
}
.mbNone {
  margin-bottom: none !important;
}


/* display styles */
.hidden {
  display: hidden !important;
}

/* visibility styles */
.visiblityHidden {
  visibility: hidden !important;
}


/* Elevatin of some component style */

.elevate {
  box-shadow: 0px 0px 20px 0px rgba(166, 166, 165, 0.5);
}

.openFileWrapper {    
  height: 90vh !important;
  width: calc(100% - 43px);
  margin: auto;
  margin-top: 42px;
  margin-left: 43px;
}

.openFileIframe {
  width: 100%;
  height: 100%;
}

.backGroundColorGrey {
  background-color: #cccccc6e !important;
}

